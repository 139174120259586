import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Register from "../features/Auth/components/Register/Register";
import Login from "../features/Auth/components/Login/Login";
import Home from "../features/Shared/components/Home/Home";
import CompleteRegistration from "../features/Auth/components/CompleteRegistration/CompleteRegistration";
import { RouterEstudios } from "./RouterEstudios";
import { RouterConfiguracion } from "./RouterConfiguracion";
import Public from "../features/Public/Public";

const Router = () => {
	let authState = useSelector((state) => state.auth.authState);
	return (
		<Routes>
			{authState ? (
				<>
					<Route index path="/*" element={<Home />} />
				</>
			) : (
				<>
					<Route path="/public/:id" element={<Public />} />
					<Route exact path="/register" element={<Register />} />
					<Route
						exact
						path="/complete-registration"
						element={<CompleteRegistration />}
					/>
					<Route index element={<Login />} />
					<Route path="*" element={<Login />} />
				</>
			)}
			<Route path="/estudios/*" element={<RouterEstudios />} />
			<Route path="/configuracion/*" element={<RouterConfiguracion />} />
		</Routes>
	);
};

export default Router;
