import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { statusSlice } from './status';
import { studiesSlice } from './studies';


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    status: statusSlice.reducer,
    studies: studiesSlice.reducer,
  },
});