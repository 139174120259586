import { useNavigate } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Captura.scss";

const Captura = () => {
	const navigate = useNavigate();

	const handleNuevoEstudio = () => {
		navigate("/estudios/captura/nuevo");
	};
	return (
		<Container fluid className="container">
			<div className="header">En captura</div>
			<div className="captura">
				<div id="buttons">
					<Button className="button" onClick={() => handleNuevoEstudio()}>
						Nuevo
					</Button>
				</div>

				<ListaEstudios status="1" />
			</div>
		</Container>
	);
};

export default Captura;
