import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Divider, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { preregisterUser } from "../../../../store/auth";
import { NavLink, useNavigate } from "react-router-dom";

import "./Register.scss";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			whatsapp: "",
			email: "",
			company: "",
			promotoria: "",
			// ine: "",
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required("Nombre es requerido"),
			apellido_paterno: Yup.string().required("Apellido paterno es requerido"),
			whatsapp: Yup.string()
				.min(10)
				.max(13)
				.required("Celular / Whatsapp es requerido"),
			email: Yup.string().email().required("Correo electrónico es requerido"),
			// company: Yup.string().required("El nombre del despacho es requerido"),
			// // ine: Yup.string().required("Identificación oficial es requerida"),
			// promotoria: Yup.string().required("Promotoría es requerida"),
		}),
		onSubmit: (formData) => {
			dispatch(
				preregisterUser(
					`${formData.nombre}`,
					`${formData.apellido_paterno}`,
					`${formData.apellido_materno}`,
					`${formData.whatsapp}`,
					`${formData.email}`,
					`${formData.company}`,
					`${formData.promotoria}`
				)
			);
			setTimeout(() => {
				navigate("/");
			}, 5000);
		},
	});
	return (
		<>
			<Form onSubmit={formik.handleSubmit} className="register">
				<div className="register__subtitle">Registro</div>
				<Form.Input
					type="text"
					placeholder="Juan"
					name="nombre"
					label="Nombre *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.nombre}
					error={formik.touched.nombre && formik.errors.nombre}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="Pérez"
					name="apellido_paterno"
					label="Apellido paterno *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.apellido_paterno}
					error={
						formik.touched.apellido_paterno && formik.errors.apellido_paterno
					}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="López"
					name="apellido_materno"
					label="Apellido materno"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.apellido_materno}
					error={
						formik.touched.apellido_materno && formik.errors.apellido_materno
					}
					className="register__input"
				/>
				<Form.Input
					type="text"
					placeholder="5512345678"
					name="whatsapp"
					label="Celular / Whatsapp *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.whatsapp}
					error={formik.touched.whatsapp && formik.errors.whatsapp}
					className="register__input"
				/>
				<Form.Input
					type="email"
					placeholder="correo@electronico.com"
					name="email"
					label="Correo electrónico *"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.email}
					error={formik.touched.email && formik.errors.email}
					className="register__input"
				/>
				<Form.Dropdown
					placeholder="Selecciona una promotoría"
					name="promotoria"
					label="Promotoría"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.promotoria}
					error={formik.touched.promotoria && formik.errors.promotoria}
					className="register__input"
					selection
					options={[
						{ key: "1", text: "Freti", value: "Freti" },
						{
							key: "2",
							text: "Estratega Lobo & Asociados",
							value: "Estratega Lobo & Asociados",
						},
						{ key: "3", text: "Profundum", value: "Profundum" },
						{ key: "4", text: "LOMA", value: "LOMA" },
						{ key: "5", text: "Otra", value: "Otra" },
					]}
				/>
				<Form.Input
					type="text"
					placeholder="Nombre del despacho"
					name="company"
					label="Despacho"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.company}
					error={formik.touched.company && formik.errors.company}
					className="register__input"
				/>
				<Form.Field className="register__input">
					<Form.Radio
						label="Con aviso de privacidad y logo de A Tiempo Planea"
						name="radioGroup"
						value="aviso1"
						// checked={value === "abogado"}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<Form.Radio
						label="Con aviso de privacidad y logo del Despacho"
						name="radioGroup"
						value="aviso2"
						// checked={value === "estudiante"}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<Form.Radio
						label="Sin aviso de privacidad ni logo"
						name="radioGroup"
						value="sinAviso"
						// checked={value === "estudiante"}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
				</Form.Field>

				{/* <Form.Field>
					<Form.Input
						type="file"
						placeholder="Identificación oficial"
						name="ine"
						label="Identificación oficial *"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.ine}
						error={formik.touched.ine && formik.errors.ine}
						className="register__input"
					/>
				</Form.Field> */}
				<div>
					<Button
						type="button"
						onClick={formik.handleReset}
						id="secondary-button"
					>
						Limpiar
					</Button>
					<Button
						type="submit"
						onClick={formik.handleSubmit}
						id="primary-button"
					>
						Enviar
					</Button>
				</div>
			</Form>
			<div className="register__text">* Campos obligatorios</div>
			<div className="register__text">
				Cuando termine su registro, recibirá por correo un enlace para registrar
				su contraseña.
			</div>
			<Divider />
			<div className="register__register">
				<NavLink to="/">
					<Button type="button">Regresar a Iniciar sesión</Button>
				</NavLink>
			</div>
		</>
	);
};

export default Login;
