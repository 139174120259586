import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	authState: false,
	permissions: [],
	token: '',
	id: '',
	username: '',
	name: '',
	role: '',
	email: '',
	company: '',
	permissionsPaths: [],
	preregistered: false,
	confirmed: false,
	barType: 'long',
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthState: (state, action) => {
			state.authState = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setId: (state, action) => {
			state.id = action.payload;
		},
		setUsername: (state, action) => {
			state.username = action.payload;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setRole: (state, action) => {
			state.role = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setCompany: (state, action) => {
			state.company = action.payload;
		},
		setPermissionsPaths: (state, action) => {
			state.permissionsPaths = action.payload;
		},
		setPreregistered: (state, action) => {
			state.preregistered = action.payload;
		},
		setBarType: (state, action) => {
			state.barType = action.payload;
		},
	},
});

export const loginActions = (value) => {
	return async (dispatch) => {
		dispatch(setAuthState(true));
		dispatch(setToken(value.data[0]));
		dispatch(setId(value.data[1].id));
		dispatch(setUsername(value.data[1].username));
		dispatch(setName(value.data[1].name));
		dispatch(setRole(value.data[1].role));
		dispatch(setEmail(value.data[1].email));
		dispatch(setCompany(value.data[1].company));
		dispatch(setBarType(value.data[1].barType));
	}
}

export const logoutActions = () => {
	return async (dispatch) => {
		dispatch(setAuthState(false));
		dispatch(setToken(null));
		dispatch(setId(null));
		dispatch(setUsername(null));
		dispatch(setName(null));
		dispatch(setRole(null));
		dispatch(setEmail(null));
		dispatch(setCompany(null));
		dispatch(setPermissions(null));
		dispatch(setPermissionsPaths(null));
		dispatch(setBarType(null));
	}
}

// Action creators 
export const { setAuthState, setPermissions, setToken, setId, setUsername, setName, setRole, setEmail, setCompany, setPermissionsPaths, setPreregistered, setBarType } = authSlice.actions;