import { NavLink } from "react-router-dom";
import { Button, Container, Divider } from "semantic-ui-react";
import "./Dashboard.scss";

const Dashboard = () => {
	return (
		<Container fluid>
			<div className="dashboard">
				<div className="title">Configuración</div>
				<div id="buttons">
					<NavLink to={"/configuracion/perfil"}>
						<Button className="button">Perfil</Button>
					</NavLink>
					<NavLink to={"/configuracion/facturacion"}>
						<Button className="button">Datos de facturación</Button>
					</NavLink>
				</div>
				<Divider />
				{/* <div id="tertiary-buttons">
					<NavLink to={"/configuracion/captura"}>
						<Button className="button">Crear un nuevo estudio</Button>
					</NavLink>
				</div> */}
			</div>
		</Container>
	);
};

export default Dashboard;
