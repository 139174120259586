import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Enproceso.scss";

const Enproceso = () => {
	return (
		<Container fluid className="container">
			<div className="header">En proceso</div>
			<div className="captura">
				<ListaEstudios status="3" />
			</div>
		</Container>
	);
};

export default Enproceso;
