import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Validacion.scss";

const Validacion = () => {
	return (
		<Container fluid className="container">
			<div className="header">Validación</div>
			<div className="captura">
				<ListaEstudios status="2" />
			</div>
		</Container>
	);
};

export default Validacion;
