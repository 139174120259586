import { Image } from "semantic-ui-react";
import Logo from "../../../../assets/Logo-grande.png";
// import { longbrand, shortbrand } from "../../../../constants/constants";

import "./Home.scss";

const Home = () => {
	return (
		<div className="home">
			{/* <div className="home__subtitle">{longbrand}</div>
			<div className="home__title">{shortbrand}</div> */}
			<Image src={Logo} centered className="home__logo" />
		</div>
	);
};

export default Home;
