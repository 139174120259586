import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Reprocesos.scss";

const Reprocesos = () => {
	return (
		<Container fluid className="container">
			<div className="header">Reprocesos</div>
			<div className="captura">
				<ListaEstudios status="5" />
			</div>
		</Container>
	);
};

export default Reprocesos;
