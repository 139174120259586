import { baseApi } from '../../api/baseApi.js'
import { setError, setErrorCode, setIsLoading, setMessage } from '../status/statusSlice'
import { loginActions, setPermissions, setPermissionsPaths, setPreregistered } from './authSlice';

export const authenticateUser = (email, password) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		try {
			const response = await baseApi.post('/users/login', {
				email,
				password,
			});
			if (!response.data) {
				dispatch(setError('Usuario o contraseña incorrectos'));
				return;
			} else if (response.data === 'inactive') {
				dispatch(setError('Usuario inactivo'));
				return;
			} else {
				dispatch(loginActions(response));
				dispatch(setError(null));
				dispatch(getUserPermissions(response.data[1].id, response.data[0]));
				dispatch(getPermissionsPaths(response.data[1].id, response.data[0]));
			}
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
	}
}

export const registerUser = (name, username, password, email, company, role, active) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		dispatch(setError(null));
		dispatch(setMessage(null))
		try {
			const response = await baseApi.post('/createUser', {
				name,
				username,
				password,
				email,
				company,
				role,
				active
			});
			if (!response.data) {
				dispatch(setError('Error al registrar usuario'));
				return;
			}
			dispatch(setError(null));
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
		dispatch(setMessage('Usuario registrado exitosamente.'))
	}
}

export const preregisterUser = (nombre, apellido_paterno, apellido_materno, whatsapp, email, company) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		dispatch(setError(null));
		dispatch(setMessage(null))
		try {
			const response = await baseApi.post('/users/preregisterUser', {
				nombre,
				apellido_paterno,
				apellido_materno,
				whatsapp,
				email,
				company
			});
			if (response.data.error) {
				dispatch(setError(response.data.error));
				return;
			}
			if (!response.data) {
				dispatch(setError('Error al registrar usuario'));
				return;
			}
			dispatch(setError(null));
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
		dispatch(setMessage('Usuario registrado exitosamente. Recibirá un correo para completar el registro.'))
	}
}

export const getUserPermissions = (id, token) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		try {
			const response = await baseApi.get(`/getUserPermissions/${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (!response.data) {
				dispatch(setError('Error al obtener permisos'));
				return;
			}
			dispatch(setPermissions(response.data));
			dispatch(setError(null));
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
	}
}

export const getPermissionsPaths = (id, token) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		try {
			const response = await baseApi.get(`/obtenerTodosPaths/${id}`, {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});
			if (!response.data) {
				dispatch(setError('Error al obtener permisos'));
				return;
			}
			dispatch(setPermissionsPaths(response.data));
			dispatch(setError(null));
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
	}
}

export const getRegistrationInfo = (id) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		dispatch(setError(null));
		dispatch(setErrorCode(null));
		try {
			const response = await baseApi.get(`/users/getPreregistered/${id}`
			);
			if (response.data) {
				dispatch(setError(`Imposible proceder: El código de autenticación no es válido, o el usuario ya está registrado`));
				dispatch(setErrorCode('JfHVhecu3x'))
				dispatch(setPreregistered(true));
				return;
			}
			dispatch(setPreregistered(false));
			dispatch(setError(null));
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
	}
}

export const setPassword = (link, password) => {
	return async (dispatch) => {
		dispatch(setIsLoading(true))
		dispatch(setError(null));
		dispatch(setErrorCode(null));
		try {
			const response = await baseApi.post(`/users/setPassword`, {
				link,
				password
			});
			if (response.data === 'error') {
				dispatch(setError('Error al establecer la contraseña'));
				return;
			}
			dispatch(setError(null));
			dispatch(setMessage('Contraseña registrada exitosamente'))
		} catch (error) {
			dispatch(setError(error.response.data));
		}
		dispatch(setIsLoading(false))
	}
}
