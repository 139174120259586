import { useEffect } from "react";
import { arrMenu } from "../../../../constants/constants.js";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Dropdown, Image, Container } from "semantic-ui-react";
import { logoutActions } from "../../../../store/auth";
import {
	setError,
	setErrorCode,
	setIsLoading,
	setMessage,
} from "../../../../store/status";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../../../assets/Logo.png";

import { hasAny } from "../../../../helpers";

import "./Navbar.scss";
import "react-toastify/dist/ReactToastify.css";

export const Navbar = () => {
	let authState = useSelector((state) => state.auth.authState);
	const barType = useSelector((state) => state.auth.barType);
	const error = useSelector((state) => state.status.error);
	const message = useSelector((state) => state.status.message);
	const permissions = useSelector((state) => state.auth.permissions);
	const permissionsPaths = useSelector((state) => state.auth.permissionsPaths);

	const dispatch = useDispatch();

	const logout = () => {
		dispatch(setIsLoading(false));
		dispatch(setError(null));
		dispatch(logoutActions());
	};

	if (error) toast.warning(error);
	if (message) toast.success(message);

	useEffect(() => {
		setTimeout(() => {
			dispatch(setError(null));
			dispatch(setErrorCode(null));
			dispatch(setMessage(null));
		}, 1000);
	}, [dispatch, error, message]);

	const menuElement = (arr, arrName, index) => {
		return (
			<Menu.Item key={index}>
				<Dropdown text={arrName}>
					<Dropdown.Menu>
						{arr.map((item, i) => {
							const current = item - 1;
							return permissionsPaths[current]?.description === "##" ||
								!permissionsPaths[current]?.path ? null : (
								<Dropdown.Item key={i}>
									<NavLink
										className={({ isActive }) =>
											isActive ? "active-link" : "inactive-link"
										}
										to={`${permissionsPaths[current]?.path}`}
									>
										<span className="link">
											{permissionsPaths[current]?.description}
										</span>
									</NavLink>
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		);
	};

	return (
		<Container fluid>
			<Menu>
				{authState ? (
					<>
						<Menu.Item>
							<NavLink to={"/"}>
								<Image src={Logo} size="mini" />
							</NavLink>
						</Menu.Item>

						{barType === "long" ? (
							arrMenu?.map((item, index) => {
								return permissions &&
									permissionsPaths &&
									hasAny(permissions, item[0])
									? menuElement(item[0], item[1], index)
									: null;
							})
						) : (
							<>
								<Menu.Item>
									<NavLink
										className={({ isActive }) =>
											isActive ? "link active-link" : "link inactive-link"
										}
										to={"/estudios/dashboard"}
									>
										Estudios
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
										className={({ isActive }) =>
											isActive ? "link active-link" : "link inactive-link"
										}
										to={"/configuracion/dashboard"}
									>
										Configuración
									</NavLink>
								</Menu.Item>
							</>
						)}

						<Menu.Item position="right" onClick={logout}>
							<span className="link">Salir</span>
						</Menu.Item>
					</>
				) : (
					<>
						<Menu.Item>
							<NavLink to={"/"}>
								<Image src={Logo} size="mini" />
							</NavLink>
						</Menu.Item>
						<Menu.Item position="right">
							<NavLink className="link" to={"/"}>
								Iniciar sesión
							</NavLink>
						</Menu.Item>
					</>
				)}
			</Menu>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				// theme="colored" // TODO: Add theme
			/>
		</Container>
	);
};
