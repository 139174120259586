import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = ({ children, redirectTo = "/home" }) => {
	let authState = useSelector((state) => state.auth.authState);

	if (!authState) {
		return <Navigate to={redirectTo} />;
	}
	return children ? children : <Outlet />;
};
