import Router from "./router/Router";
// import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { Navbar } from "./features/Shared/components/Navbar/Navbar";
// import Home from "./features/Shared/components/Home/Home";

import "./App.scss";

function App() {
	return (
		<Container fluid className="App">
			<Navbar />
			<Router />
		</Container>
	);
}

export default App;
