import { NavLink } from "react-router-dom";
import { Button, Container, Divider } from "semantic-ui-react";
import "./Dashboard.scss";

const Dashboard = () => {
	return (
		<Container fluid>
			<div className="dashboard">
				<div className="title">Estudios</div>
				<div id="buttons">
					<NavLink to={"/estudios/captura"}>
						<Button className="button">Captura</Button>
					</NavLink>
					<NavLink to={"/estudios/validacion"}>
						<Button className="button">Validación</Button>
					</NavLink>
					<NavLink to={"/estudios/enproceso"}>
						<Button className="button">Proceso</Button>
					</NavLink>
					<NavLink to={"/estudios/completados"}>
						<Button className="button">Completados</Button>
					</NavLink>
					<NavLink to={"/estudios/reprocesos"}>
						<Button className="button">Reprocesos</Button>
					</NavLink>
				</div>
				<Divider />
			</div>
		</Container>
	);
};

export default Dashboard;
