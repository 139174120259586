import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Compressor from "compressorjs";
import { Button, Container, Dropdown, Modal, Table } from "semantic-ui-react";
import {
	changeStatus,
	deleteStudy,
	getDetails,
	uploadFile,
} from "../../../../store/studies";
import axios from "axios";

import "./DetalleEstudio.scss";
import { setError, setMessage } from "../../../../store/status";

const DetalleEstudio = () => {
	const [abrirModal, setAbrirModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [tipoImagen, setTipoImagen] = useState("");
	const [finalFile, setFinalFile] = useState(null);
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const baseurl = "https://api.atiempoplanea.mx";

	useEffect(() => {
		dispatch(getDetails(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const estudio = useSelector((state) => state.studies.details);

	const handleEditar = () => {
		console.log("Editar"); // TODO: remove me
	};

	const handleAbrirModal = () => {
		console.log("abrir modal"); // TODO: remove me
		setSelectedFile(null);
		setTipoImagen("");
		setAbrirModal(true);
	};

	const handleCancelUpload = () => {
		console.log("Cancelar"); // TODO: remove me
		setAbrirModal(false);
	};

	const handleImageTypeChange = (_e, data) => {
		console.log("data.value", data.value); // TODO: remove me
		setTipoImagen(data.value);
	};

	const onFileChange = (e) => {
		console.log("file", e.target.files[0]); // TODO: remove me
		setSelectedFile(e.target.files[0]);
	};

	const handleFileUpload = () => {
		dispatch(uploadFile(id, tipoImagen, selectedFile));
		console.log("subiendo archivo"); // TODO: remove me
		console.log("id", id); // TODO: remove me
		console.log("tipoImagen", tipoImagen); // TODO: remove me
		console.log("selectedFile", selectedFile); // TODO: remove me
		setAbrirModal(false);
		const fileType = selectedFile.type;
		console.log("fileType", fileType); // TODO: remove me
		if (fileType === "application/pdf") {
			setFinalFile(selectedFile);
		} else if (
			fileType === "image/jpeg" ||
			fileType === "image/png" ||
			fileType === "image/gif"
		) {
			if (selectedFile) {
				new Compressor(selectedFile, {
					quality: 0.5,
					success: (compressedResult) => {
						setFinalFile(compressedResult);
					},
				});
			}
		} else {
			alert("Seleccione una imágen válida");
		}
		if (finalFile) {
			const formData = new FormData();
			formData.append("image", finalFile, selectedFile.name);
			formData.append("id", id);
			formData.append("tipoImagen", tipoImagen);
			const url = `${baseurl}/studies/uploadFile`;
			axios
				.post(url, formData)
				.then((response) => {
					if (response.status === 200) {
						dispatch(setMessage("Imagen guardada"));
					} else {
						dispatch(setError("Error al guardar imagen"));
					}
					setSelectedFile(null);
					setTipoImagen("");
				})
				.catch((error) => {
					console.error("error", error);
				});
		}
	};

	const handleEliminar = () => {
		dispatch(deleteStudy(id));
		setTimeout(() => {
			navigate(-1);
		}, 1000);
	};

	const handleEnviarValidar = () => {
		dispatch(changeStatus(id, 2));
	};

	const ModalCapturaImagen = () => (
		<Modal
			open={abrirModal}
			size="tiny"
			className="modal"
			closeOnDimmerClick={false}
		>
			<Modal.Header>Captura de imagen</Modal.Header>
			<Modal.Content>
				<Dropdown
					placeholder="Selecciona el tipo de imagen"
					onChange={handleImageTypeChange}
					selection
					options={[
						{
							key: 1,
							text: "Comprobante de pago",
							value: "comprobante_pago_imagen",
						},
						{ key: 2, text: "RFC", value: "rfc_imagen" },
						{ key: 3, text: "CURP", value: "curp_imagen" },
						{
							key: 4,
							text: "Estado de cuenta del Afore",
							value: "afore_imagen",
						},
						{
							key: 5,
							text: "Identificación (anverso)",
							value: "identificacion_anverso_imagen",
						},
						{
							key: 6,
							text: "Identificación (reverso)",
							value: "identificacion_reverso_imagen",
						},
					]}
					value={tipoImagen}
					className="dropdown"
				/>
				<div>
					<label for="hidden-new-file" class="ui icon button input-file">
						<i class="cloud icon"></i> Seleccionar archivo
					</label>
					<input
						type="file"
						id="hidden-new-file"
						className="input-button"
						onChange={onFileChange}
					/>
				</div>
				<div className="botonera">
					<Button className="ui button" onClick={handleCancelUpload}>
						Cancelar
					</Button>
					<Button
						className={`ui button ${
							selectedFile && tipoImagen ? "primary-button" : ""
						}`}
						id={`${
							selectedFile && tipoImagen ? "primary-button" : "disabled-button"
						}`}
						onClick={handleFileUpload}
						disabled={!selectedFile || !tipoImagen}
					>
						Subir
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);

	return (
		<Container fluid className="container">
			<div className="header">Detalles</div>
			<div className="detalle-estudio">
				<Table striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Campo</Table.HeaderCell>
							<Table.HeaderCell>Valor</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>Nombre</Table.Cell>
							<Table.Cell>{estudio?.nombre}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Apellido Paterno</Table.Cell>
							<Table.Cell>{estudio?.apellido_paterno}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Apellido Materno</Table.Cell>
							<Table.Cell>{estudio?.apellido_materno}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>RFC</Table.Cell>
							<Table.Cell>{estudio?.rfc}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>CURP</Table.Cell>
							<Table.Cell>{estudio?.curp}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>NSS</Table.Cell>
							<Table.Cell>{estudio?.nss}</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>

			<div className="botonera">
				<Button onClick={handleAbrirModal}>Captura de imágenes</Button>
			</div>

			<ModalCapturaImagen />

			<div className="botonera">
				<button onClick={() => handleEditar()} className="ui button">
					Editar
				</button>
				<button onClick={() => handleEliminar()} className="ui button">
					Eliminar
				</button>
				{estudio?.status === 1 && (
					<button onClick={() => handleEnviarValidar()} className="ui button">
						Enviar a Validación
					</button>
				)}
			</div>
			<div className="botonera">
				<button onClick={() => navigate(-1)} className="ui button">
					Regresar
				</button>
			</div>
		</Container>
	);
};

export default DetalleEstudio;
