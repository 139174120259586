import { Button, Icon, Popup, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getList } from "../../../../store/studies";

import "./ListaEstudios.scss";

const ListaEstudios = ({ status }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user_id = useSelector((state) => state.auth.id);

	useEffect(() => {
		dispatch(getList(user_id, status));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const lista = useSelector((state) => state.studies.list);

	return (
		<>
			<div className="subheader">Lista</div>
			<Table className="table">
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>Nombre</Table.HeaderCell>
						<Table.HeaderCell>RFC</Table.HeaderCell>
						<Table.HeaderCell>CURP</Table.HeaderCell>
						<Table.HeaderCell>Acción</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{lista.map((item) => {
						return (
							<Table.Row key={item.id}>
								<Table.Cell>{item.id}</Table.Cell>
								<Table.Cell>{item.rfc}</Table.Cell>
								<Table.Cell>{item.curp}</Table.Cell>
								<Table.Cell>
									{item.nombre} {item.apellido_paterno} {item.apellido_materno}
								</Table.Cell>
								<Table.Cell>
									<span>
										<NavLink to={`/estudios/captura/detalles?id=${item.id}`}>
											<Popup
												content="Ir a los detalles de este estudio"
												trigger={<Button>Detalles</Button>}
											/>
										</NavLink>
										{item.status === 4 && (
											<Popup
												content="Copia un link para compartir con el cliente"
												trigger={
													<Icon name="copy outline" color="grey" size="large" />
												}
											/>
										)}
										{item.status === 4 && (
											<Popup
												content="Manda el acceso al estudio a tu correo electrónico"
												trigger={
													<Icon
														name="envelope outline"
														color="grey"
														size="large"
													/>
												}
											/>
										)}
										{item.status === 4 && (
											<Popup
												content="Solicita un reproceso"
												trigger={
													<Icon name="recycle" color="grey" size="large" />
												}
											/>
										)}
									</span>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
			<div className="botonera">
				<button onClick={() => navigate(-1)} className="ui button">
					Regresar
				</button>
			</div>
		</>
	);
};

export default ListaEstudios;
