export const shortbrand = "A Tiempo Planea";

export const brand = "A Tiempo Planea";

export const leyend = "Planeando tu futuro";

export const longbrand = "Planeando tu futuro";

export const arrEstudios = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const arrEstudiosName = "Estudios";

// Sections should be added here

// export const arrSga = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
// export const arrSgaName = "SGA";

export const arrMenu = [
	[arrEstudios, arrEstudiosName],
	// [arrSga, arrSgaName],
];