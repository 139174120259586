import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Divider, Form, Image } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "../../../../store/auth";
import Logo from "../../../../assets/Logo-grande.png";

import "./Login.scss";
import { setError, setErrorCode } from "../../../../store/status";
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.status.isLoading);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object({
			email: Yup.string().required("El correo electrónico es requerido"),
			password: Yup.string().required("La contraseña es requerida"),
		}),
		onSubmit: (formData) => {
			dispatch(authenticateUser(`${formData.email}`, `${formData.password}`));
		},
	});

	useEffect(() => {
		// navigate to /
		navigate("/");
		dispatch(setError(null));
		dispatch(setErrorCode(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Form onSubmit={formik.handleSubmit} className="login">
				<Image src={Logo} centered className="login__logo" />
				<div className="login__subtitle">Iniciar sesión</div>
				<Form.Input
					type="email"
					placeholder="correo@electronico.com"
					name="email"
					label="Correo electrónico"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.email}
					error={formik.touched.email && formik.errors.email}
					className="login__input"
				/>
				<Form.Input
					type="password"
					placeholder="SuperSecretPassword"
					name="password"
					label="Contraseña"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.password}
					error={formik.touched.password && formik.errors.password}
					className="login__input"
				/>
				<div>
					<Button
						type="button"
						onClick={formik.handleReset}
						id="secondary-button"
						disabled={isLoading}
					>
						Limpiar
					</Button>
					<Button
						type="submit"
						onClick={formik.handleSubmit}
						id="primary-button"
						disabled={isLoading}
					>
						Enviar
					</Button>
				</div>
			</Form>
			<Divider />
			<div className="login__register">
				<div className="login__register-text">Aún no tienes cuenta?</div>
				<NavLink to="/register">
					<Button type="button">Regístrarse</Button>
				</NavLink>
			</div>
		</>
	);
};

export default Login;
