import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Login from "../features/Auth/components/Login/Login";

import Dashboard from "../features/Configuracion/Dashboard";
import Perfil from "../features/Configuracion/Perfil/Perfil";
import Facturacion from "../features/Configuracion/Facturacion/Facturacion";

export const RouterConfiguracion = () => {
	let authState = useSelector((state) => state.auth.authState);
	return (
		<Routes>
			{authState ? (
				<Route element={<ProtectedRoute />}>
					<Route exact path="/dashboard" element={<Dashboard />} />
					<Route exact path="/perfil" element={<Perfil />} />
					<Route exact path="/facturacion" element={<Facturacion />} />
				</Route>
			) : (
				<>
					<Route index element={<Login />} />
					<Route path="*" element={<Login />} />
				</>
			)}
		</Routes>
	);
};
